import React from "react";
import { graphql, Link } from "gatsby";
import _ from "lodash";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { IntlProvider, FormattedMessage } from "react-intl";

import Layout from "../../components/layout";
import LocalesContext from "../../context/LocalesContext";
import messages from "../../../i18n-translations.json";

const NotFoundPage = ({ data }) => {
  const allWpPost = data.allWpPost;

  return (
    <>
      <div className="wrapper-404">
        <h1>
          <FormattedMessage id="404_title" defaultMessage="404" />
        </h1>
        <p>
          <FormattedMessage id="404_subtitle" />
        </p>
        <span>
          <FormattedMessage id="404_copy" />
          <Link to={`/nl/`}>
            <FormattedMessage id="404_home" />
          </Link>
        </span>
        <div className="latest-posts">
          <h4>
            <span>
              <FormattedMessage id="404_latest_posts" />
            </span>
          </h4>
          <div className="wrapper-posts">
            <div className="posts">
              {_.map(allWpPost.nodes, (post, i) => {
                const { title, categories, seo, id, originalSlug } = post;
                
                let thumbnail, alt;
                if( post.featuredImage !== null && post.featuredImage.node !== null ) {
                  thumbnail = post.featuredImage?.node.mediaItemUrl;
                  alt = post.featuredImage?.node.slug;
                } else {
                  thumbnail = 'thumbnail';
                  alt = 'alt';
                }
                return (
                  <React.Fragment key={i}>
                    <Seo post={seo} />
                    <div className="post" key={id}>
                      <div className="post-img">
                        <Link to={`/nl/${originalSlug}/`}>
                          <div className="gatsby-image-wrapper">
                            <img
                              data-src={thumbnail}
                              alt={alt}
                              className="lazyload"
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="post-content">
                        <Link to={`/nl/category/${categories.nodes[0]?.slug}`}>
                          <span>{categories.nodes[0]?.name}</span>
                        </Link>
                        <Link to={`/nl/${originalSlug}/`}>
                          <h2>{title}</h2>
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Page404 = (props) => {
  return (
    <IntlProvider locale={"nl"} messages={messages["nl"]}>
      <LocalesContext.Provider
        value={{
          slug: "",
          avaiableLangs: ["en", "da", "de", "es", "fr", "it", "nl", "nb", "pl", "pt", "fi", "sv", "tr", "el", "ru", "ja", "ko"],
          pageNumber: 0,
        }}
      >
        <Layout>
          <NotFoundPage data={props.data} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default Page404;
export const query = graphql`
  query ($defaultCategorySlugs: [String]) {
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: $defaultCategorySlugs } } }
        }
        language: { slug: { eq: "nl" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        featuredImage {
          node {
            title
            mediaItemUrl
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`;
